import { AppBar, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, LinearProgress, TextField, Toolbar } from '@mui/material/'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PostData } from '../services/PostData'
import ItemDocente from './ItemDocente'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { GetData } from '../services/GetData'
import CloseIcon from '@mui/icons-material/Close';
import { useContext } from 'react'
import { SessionContext } from '../SessionContext'


const Docentes = () => {

    const [listaDocentes, setListaDocentes] = useState([]);
    const [listaCarga, setListaCarga] = useState([]);
    const [loading, setLoading] = useState(false)
    const [LoadingSave, setLoadingSave] = useState(false)
    const [correo, setCorreo] = useState("")
    const [password, setPassword] = useState("")
    const [open, setOpen] = React.useState(false);
    const [openDatos, setOpenDatos] = React.useState(false);
    const [openCarga, setOpenCarga] = React.useState(false);

    const [IdDocente, setIdDocente] = useState("0")
    const [Nombres, setNombres] = useState("")
    const [Identificacion, setIdentificacion] = useState("")
    const [Celular, setCelular] = useState("")
    const [Direccion, setDireccion] = useState("")
    const [FechaN, setFechaN] = useState("")
    const [Dcorreo, setDCorreo] = useState("")
    const [Titulo, setTitulo] = useState("")
    const [formValidado, setformValidado] = useState(false);
    const [UrlImpresion, setUrlImpresion] = useState('');
    const [openImpresion, setopenImpresion] = useState(false) 
    const { user: usuario } = useContext(SessionContext);
    const [Periodo, setPeriodo] = useState(usuario.periodo);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {          
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            background: 'linear-gradient(90deg, rgba(9,53,121,1) 60%, rgba(138,184,228,1) 100%)',
            color: theme.palette.common.white,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      useEffect(() => {
            setformValidado(false)
            if (Nombres && emailRegex.test(Dcorreo) && Identificacion && Celular && Direccion && Titulo){
                setformValidado(true)
            }
      }, [Nombres, Dcorreo, Identificacion, Celular, Direccion, Titulo])
      

      const validateFechaNacimiento = (fecha) => {
            const fechaNacimiento = new Date(fecha);
            const fechaActual = new Date();
            return fechaNacimiento < fechaActual;
      };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setCorreo("")
        setPassword("")
        setOpen(false);
    };
    const handleDatosClose = () => {
        setOpenDatos(!openDatos);
    };

    const handleCargaClose = () => {
        setOpenCarga(!openCarga);
    };

    const GuardarDocente = () => {

        const datos={       
            id_docente:IdDocente,     
            nombre:Nombres,
            identificacion:Identificacion,
            celular:Celular,
            direccion:Direccion,
            fecha_nac:FechaN,
            email:Dcorreo,
            titulo:Titulo,
        }
        
        setLoadingSave(true)
        PostData('docentes/update/', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                
                setLoadingSave(false)
                toast.success('Docente guardado con exito', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                listado_alumno();
            }else{
                setLoadingSave(false)
                toast.error(responseJSON.mensaje, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
               
            }
        });
    }

    useEffect(() => {
        listado_alumno()
    }, [])
    
      const listado_alumno = () => {

        const datos = {

        }
        setLoading(true)
        PostData('docentes/lista/', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                setListaDocentes(responseJSON.docentes);
                setLoading(false)
                
            }
        });

      }

      const crearUsuario = (id_docente) => {
        const datos = {
            id_docentes: id_docente,
            id_usuarios: 0
        }
        handleClickOpen()  
        setLoading(true)
        PostData('docentes/crearusuario/', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){            
                setCorreo(responseJSON.email)
                setPassword(responseJSON.password)
                setLoading(false)        
                toast.success('Contraseña generada con exito', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });                      
            }else{
                handleDatosClose()  
            }
        });

      }

      const ConfirmarCrearUsuario = (id_docente, nombre) => {
        Swal.fire({
            title: 'Crear usuario',
            text: '¿Deseas crear un nuevo usuario para el Docente: '+ nombre+ '?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
                crearUsuario(id_docente)              
            }
          });
      }

      const ListaCargaDoc = (id) => {
            setOpenCarga(true)
            setListaCarga([])
            GetData('asignaciones/cargaacademicadoc/' + id + '/', null).then ((result) =>{
                let responseJSON = result;
                if (responseJSON.error === false){                       
                    setListaCarga(result.carga)             
                }else{
                   
                }
            });

      }

      const DatosDocente = (id) => {
            setLoadingSave(true)
            setIdDocente("0")
            setCorreo("")
            setNombres("")
            setIdentificacion("")
            setCelular("")
            setDireccion("")
            setFechaN("")
            setDCorreo("")
            setTitulo("")
            setOpenDatos(true)
            GetData('docentes/datos/' + id + '/', null).then ((result) =>{
                let responseJSON = result;
                if (responseJSON.error === false){    
                    const datos = result.datos;        
                    setIdDocente(datos.id_docentes)
                    setDCorreo(datos.email_doc)
                    setNombres(datos.nombres_apellidos_doc)
                    setIdentificacion(datos.identificacion_doc)
                    setCelular(datos.telefonos_doc)
                    setDireccion(datos.direccion_doc)
                    setFechaN(datos.fecha_nacimiento_doc)
                    setTitulo(datos.titulo_doc)             
                    
                    setLoadingSave(false)
                }else{
                   
                }
            });
            
      }

      const handleCloseImpresion = () => {
        setopenImpresion(false);
      };


      const PlanillasDocente = (id) => {
            setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_calificaciones_doc.php?id_docente=' + id + '&periodo=' + Periodo + '&token=' + usuario.token);
            setopenImpresion(true)
      }


  return (
    <>
    <Container maxWidth="lg">

        <Card>
            <CardHeader color="warning">
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                    <h4 className="H4tabla" >Listado de Docentes</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/`} color="primary"> Regresar </Button>
                    </Grid>
                </Grid>
            </CardHeader>
            <CardContent>
                <LinearProgress style={{visibility: loading ? 'visible' : 'hidden' }} />
                
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <StyledTableRow>
                                  <StyledTableCell key="{th0}" width="5%" >Código</StyledTableCell>
                                  <StyledTableCell key="{th1}" width="30%" align="left">Nombre del docente</StyledTableCell>
                                  <StyledTableCell key="{th2}" width="20%" align="left">Teléfonos</StyledTableCell>
                                  <StyledTableCell key="{th3}" width="20%" align="left">E-mail</StyledTableCell>
                                  <StyledTableCell key="{th4}" width="20%" align="left">Op</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                                
                                  <ItemDocente listaDocentes={listaDocentes} ConfirmarCrearUsuario={ConfirmarCrearUsuario} DatosDocente={DatosDocente} ListaCargaDoc={ListaCargaDoc} PlanillasDocente={PlanillasDocente} />
                                    
                              </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
        </Container>      

        
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'common.white' }} >Creación de Usuario</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <p style={{ marginTop: '1rem' }}>
                    Aquí tienes la información de inicio de sesión del docente.
                    Asegúrate de compartir estos detalles de forma segura y confidencial.
                </p>
                <div>
                    <strong>Correo:</strong> {correo}
                </div>
                <div>
                    <strong>Contraseña:</strong> {password}
                </div>
               
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Aceptar
            </Button>
            </DialogActions>
      </Dialog>

      <Dialog
            open={openCarga}
            onClose={handleCargaClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'common.white' }} >Carga Académica Docente</DialogTitle>
            <DialogContent>
                        
                     <TableContainer component={Paper} sx={{marginTop: '20px'}}>
                        <Table sx={{ minWidth: 400 }} size="small" aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{fontWeight:'bold'}}>Grado</TableCell>
                                    <TableCell align="center" style={{fontWeight:'bold'}}>Grupo</TableCell>
                                    <TableCell style={{fontWeight:'bold'}}>Asignatura</TableCell>
                                    <TableCell align="center" style={{fontWeight:'bold'}}>I.H</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                               {listaCarga.map((carga) => (
                                    <StyledTableRow2  key={carga.nombre_asignatura}>
                                        <StyledTableCell2 align="center" >{carga.nombre_grado}</StyledTableCell2>
                                        <StyledTableCell2 align="center" >{carga.id_grupo}</StyledTableCell2>                                        
                                        <StyledTableCell2 align="left" >{carga.nombre_asignatura}</StyledTableCell2>
                                        <StyledTableCell2 align="center" >{carga.intensidad_horaria}</StyledTableCell2>                                        
                                    </StyledTableRow2>
                               ))} 
                               
                            </TableBody>
                        </Table>
                    </TableContainer>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCargaClose} color="primary">
                Cerrar
            </Button>
            </DialogActions>
      </Dialog>

        <Dialog
            open={openDatos}
            onClose={handleDatosClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'common.white' }} >Datos Docente</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {LoadingSave ?  <LinearProgress /> :
                <Grid container spacing={2} >
                     <Grid item xs={8}>
                            <TextField fullWidth 
                                        id="nombres" 
                                        label="Nombres y Apellidos" 
                                        value={Nombres} 
                                        onChange={(e) => setNombres(e.target.value)} 
                                        helperText={Nombres === "" ? "El campo no puede estar vacío" : ""}
                                        error={Nombres === ""}
                                        variant="standard" />
                     </Grid>   
                     <Grid item xs={4}>
                            <TextField fullWidth id="identificacion" label="Identificación" 
                                        value={Identificacion} 
                                        helperText={Identificacion === "" ? "El campo no puede estar vacío" : ""}
                                        error={Identificacion === ""}
                                        onChange={(e) => setIdentificacion(e.target.value)} 
                                        variant="standard" />
                     </Grid>   
                     <Grid item xs={4}>
                            <TextField fullWidth id="celular"
                                                 label="Célular" 
                                                 variant="standard"
                                                  value={Celular} 
                                                  helperText={Celular === "" ? "El campo no puede estar vacío" : ""}
                                                  error={Celular === ""}
                                                  onChange={(e) => setCelular(e.target.value)} /> 
                     </Grid>   
                     <Grid item xs={8}>
                            <TextField fullWidth 
                                            id="direccion" 
                                            label="Dirección" 
                                            variant="standard" 
                                            value={Direccion} 
                                            helperText={Direccion === "" ? "El campo no puede estar vacío" : ""}
                                            error={Direccion === ""}
                                            onChange={(e) => setDireccion(e.target.value)}  />
                     </Grid>   
                     <Grid item xs={4}>
                            <TextField fullWidth type='date' 
                                        id="fechan" 
                                        label="Fecha Nacimiento" 
                                        variant="standard" 
                                        value={FechaN} 
                                        error={!validateFechaNacimiento(FechaN)}
                                        onChange={(e) => setFechaN(e.target.value)}  />
                     </Grid>   
                     <Grid item xs={8}>
                            <TextField fullWidth type='email' id="correo" label="Correo electrónico" variant="standard" value={Dcorreo} 
                                    onChange={(e) => setDCorreo(e.target.value)}
                                    error={!emailRegex.test(Dcorreo)} // Validar el formato de correo utilizando la expresión regular
                                    helperText={!emailRegex.test(Dcorreo) ? "Ingrese un correo electrónico válido" : ""}
                                    />
                     </Grid>   
                     <Grid item xs={12}>
                            <TextField fullWidth id="titulo" label="Título profesional" 
                                        variant="standard" 
                                        value={Titulo}                                        
                                        onChange={(e) => setTitulo(e.target.value)}  
                                        />
                     </Grid>   

                </Grid>
                }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={GuardarDocente} color="primary" disabled={!formValidado}>
                Guardar
            </Button>
            <Button onClick={handleDatosClose} color="secondary">
                Cerrar
            </Button>
            </DialogActions>
      </Dialog>


      <Dialog
                    fullScreen
                    open={openImpresion}
                    onClose={handleCloseImpresion}
                    
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseImpresion}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Button color="inherit" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <div>
                        <iframe src={UrlImpresion} title='Imprimir Planilla' className="inframemax">
                        </iframe> 
                    </div>
                </Dialog>
    </>
  )
}

export default Docentes