import React, { useContext } from 'react';
import './NavBar.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {Link} from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


import  Avatar  from '@mui/material/Avatar';
import { CalendarToday, FolderShared, Home, AppsOutage, AlignHorizontalCenter, AddTaskOutlined, DirectionsRunOutlined, ConnectWithoutContactOutlined, HailOutlined, Filter1Outlined, TrendingUpOutlined } from '@mui/icons-material';

import { SessionContext } from '../../SessionContext';

const NavBar = ({datosusuario}) => {

  const [openMen, setOpenMenu] = React.useState(false);
  const [openUsuario, setOpenUsuario] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = useContext(SessionContext);

  const handleDrawerOpen = () => {
       setOpenMenu(!openMen);
  };

  const handleMenuUsuario = () => {
        setOpenUsuario(!openUsuario);
  };


  const cerrar_session = () => {
        logout();
  }
  const abrir_perfil = () => {

  }

  
  const renderMenuPerfil = (
    <>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openUsuario}
            onClose={handleMenuUsuario}
          >
            <MenuItem component={Link} to={'/home/perfildeusuario'} onClick={abrir_perfil} >
                <Person2OutlinedIcon color='primary' /> Perfil de Usuario
            </MenuItem>
            <MenuItem onClick={cerrar_session}>
              <ExitToAppOutlinedIcon color='primary' /> Salir
            </MenuItem>
          </Menu>
        </>
  );



  const drawerWidth = 260;

  const styles = {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      justifyContent: 'flex-end',
    },
  };

  return (
    <>
        <div className="root" >
          <AppBar position="static">
            <Toolbar>
              <IconButton className="menuButton" color="inherit" aria-label="Menu" onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
              <Avatar>
                  <img src="/images/LogoCnotas.png" alt="logo" />
              </Avatar>
              <Typography variant="h6" color="inherit" className="grow">
                
              </Typography>

              
              <Typography variant="subtitle1" color="inherit">
                  {datosusuario.nombre_usuario}
              </Typography>
              
              <IconButton
                aria-owns={openMen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={handleMenuUsuario}
                color="inherit"
              >
               <AccountCircle />
              </IconButton>
            </Toolbar>
          </AppBar>

          {renderMenuPerfil}
          
          <Drawer
              classes={{
                paper: styles.drawerPaper,
              }}
              variant="persistent"
              anchor="left"
              open={openMen}
              
            >             
            <div className={styles.drawerHeader}>
              
              <IconButton onClick={handleDrawerOpen}>
                {openMen === true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              <span className="titulomenu_drawer">Menú Principal</span>
            </div>
            <Divider />            
              <List>
                  <ListItemButton key={1} component={Link} to={'/home'} onClick={() => setOpenMenu(false) }>
                    <ListItemIcon><Home color='primary' /></ListItemIcon>
                    <ListItemText primary="Inicio" />
                  </ListItemButton>
                  <ListItem key={2} component={Link} to={'/home/docentesporc'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><AlignHorizontalCenter color='primary' /></ListItemIcon>
                        <ListItemText primary="Porcentaje de Ingreso" />
                      </ListItem>
                      <ListItem key={5} component={Link} to={'/home/alumnosprimerosp'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><Filter1Outlined color='primary' /></ListItemIcon>
                        <ListItemText primary="Primeros Puestos Final" />
                      </ListItem>
                      <ListItem key={5} component={Link} to={'/home/alumnosperdidos'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><DirectionsRunOutlined color='primary' /></ListItemIcon>
                        <ListItemText primary="Estudiantes Año Perdido" />
                      </ListItem>
                      <ListItem key={5} component={Link} to={'/home/alumnosnivelan'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><ConnectWithoutContactOutlined color='primary' /></ListItemIcon>
                        <ListItemText primary="Estudiantes a Nivelación" />
                      </ListItem>
                      <ListItem key={5} component={Link} to={'/home/alumnospromovidos'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><HailOutlined color='primary' /></ListItemIcon>
                        <ListItemText primary="Estudiantes Promovidos" />
                      </ListItem>
                  {parseInt(datosusuario.id_tipo_usuario) === 0 &&
                    <>
                      <ListItem key={3} component={Link} to={'/home/docentes'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><FolderShared color='primary' /></ListItemIcon>
                        <ListItemText primary="Docentes" />
                      </ListItem>

                      <ListItem key={4} component={Link} to={'/home/planillas'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><AppsOutage color='primary' /></ListItemIcon>
                        <ListItemText primary="Planillas Docentes" />
                      </ListItem>
                      <ListItem key={4} component={Link} to={'/home/nivelacion'} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><TrendingUpOutlined color='primary' /></ListItemIcon>
                        <ListItemText primary="Nivelaciones" />
                      </ListItem>


                      
                      <ListItem key={6} style={{display: datosusuario.id_tipo_usuario === "0" ? '' : 'none' }} 
                        component={Link} to={`/home/periodos/`} onClick={() => setOpenMenu(false) }>
                        <ListItemIcon><CalendarToday color='primary' /></ListItemIcon>
                        <ListItemText primary="Configurar Períodos" />
                      </ListItem>                  
              
                    </>  
                  }
                  <Divider />

                   {/*
                  <ListItem button key={4} component={Link} to={'/home/asignaturasdocente/'} onClick={() => this.setState({openMenu:false}) }>
                    <ListItemIcon><i className="material-icons colorAzul" >assignment_turned_in</i></ListItemIcon>
                    <ListItemText primary="Notas Perídos Anterior" />
                  </ListItem>
                 
                  <ListItem button key={5}>
                    <ListItemIcon><i className="material-icons colorAzul">insert_chart_outlined</i></ListItemIcon>
                    <ListItemText primary="Informes" />
                  </ListItem>
                  */}
                  <ListItemButton  key={6} onClick={cerrar_session}>
                    <ListItemIcon><ExitToAppIcon  color='primary' /></ListItemIcon>
                    <ListItemText primary="Salir" />
                  </ListItemButton>                                                      

              </List>

            </Drawer>          


        </div>

    </>
  )
}

export default NavBar;

