import React, { useEffect, useState } from "react";
import { TextField, LinearProgress, Grid } from "@mui/material";
import { Check, Clear, Delete } from "@mui/icons-material/";
import { PostData } from "../services/PostData";
import { Tooltip } from "../../node_modules/@mui/material/index";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const NotasInput = ({id, value, actualizarNota, focusNextInput, Grado, Periodo, idAsignatura,id_matricula, num_nota, usuario}) => {

        const notaasig = value ? Number(value).toFixed(1) : '';
        const [nota, setNota] = useState(notaasig);
        const [loading, setLoading] = useState(false);
        const [guardado, setGuardado] = useState(false);
        const [Borrar, setBorrar] = useState(false);
        const [error, setError] = useState(false);
        const [showDelete, setShowDelete] = useState(false);
        const [openAlert, setOpenAlert] = useState(false);

      //  console.log("grado:", Grado, "periodo:",  Periodo, "asign:", idAsignatura, "id_matr: ", id_matricula)

        const Alert = React.forwardRef(function Alert(props, ref) {
            return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
          });
        
        
        const handleChange = (event) => {
            const { value } = event.target;
            // Validar que solo se ingresen números con punto decimal
            const regex = /^\d*\.?\d*$/;
            if (regex.test(value) || value === "") {
                if (value !== ".") 
                   setNota(value);
            }
          };

         useEffect(() => {
             if (!nota)
                setBorrar(false)
             else
                setBorrar(true);   
        }, [nota]) 
        
        
        const handleGuardar = async () => {

            if (!nota){
                focusNextInput(id);
                return;
            }

            if (nota < 0 || nota > 5){
                setNota('')
                setOpenAlert(true);
                return;
            }
            const [grado, grupo] = Grado.split('-');


            const datos = {
                cod_grado: grado,
                id_grupo: grupo,
                id_asignatura: idAsignatura,
                id_periodo: Periodo,
                id_matricula: id_matricula,
                num_nota: num_nota,
                nota: nota,
              }
              
              setLoading(true);
              focusNextInput(id); //lleva el foco al siguente input
              const result = await PostData(`calificaciones/guardarnotaadmin/`, datos, usuario);
              const responseJSON = result;
      
              if (responseJSON.error === false) {
                    setLoading(false);
                    setGuardado(true);
                    actualizarNota(nota);
                    
                    setTimeout(() => {
                        setGuardado(false);
                        //setBorrar(true)
                    }, 1600);
              }else{
                setLoading(false);
                setGuardado(false);
                setError(true)
              }
              
        }

        const handleFocus = (event) => {
            event.target.select();
          };

        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleGuardar();
            }
          };
        
          const handleClose = (event, reason) => {
            setOpenAlert(false);
         }

         const borrarNota = async () => {
                const [grado, grupo] = Grado.split('-');

                const datos = {
                  cod_grado: grado,
                  id_grupo: grupo,
                  id_asignatura: idAsignatura,
                  id_periodo: Periodo,
                  id_matricula: id_matricula,
                  num_nota: num_nota,
                  nota: null,
                }
                setLoading(true);
                const result = await PostData(`calificaciones/deletenotaadmin/`, datos, usuario);
                const responseJSON = result;
                if (responseJSON.error === false) {
                    setLoading(false);
                    setNota('');
                }

         }
        
        return (
            <>
            <Grid container alignItems="center" 
                    onMouseEnter={() => setShowDelete(nota !== "")}
                    onMouseLeave={() => setShowDelete(false)}>  

            <Grid item xs={9} sm={9}>
                <TextField
                id={id}
                variant="standard"
                size="small"
                value={nota}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={loading}
                inputProps={{min: 0, style: { textAlign: 'center' }}}
                onFocus={handleFocus}
                fullWidth
                />
            </Grid>
            <Grid item xs={3} sm={3} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", flexWrap: "nowrap" }}>                
                {showDelete && !error && <Tooltip title="Borrar Nota"><Delete onClick={borrarNota} style={{ color: "red", marginRight: "0px", padding: '1px', cursor: 'pointer' }} /></Tooltip> }
                {guardado && <Check style={{ color: "green", marginRight: "15px", paddingTop: '16spx' }} />}
                {error && <Clear style={{ color: "red", marginRight: "15px", paddingTop: '16spx' }} />}
            </Grid>
            <Grid xs={12}>
                {loading && <LinearProgress style={{ marginRight: "10px" }} /> }
            </Grid>
        </Grid>
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                La nota no puede ser mayor a 5.0
            </Alert>
        </Snackbar>
        </>
        );
  
}
